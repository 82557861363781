<template>
    <section class="developments my-5">
        <h2 class="developments__title mt-3 mb-5">Новости</h2>

        <div class="row row-cols-1 row-cols-md-3 g-1">
            <div
                class="col"
                v-for="(news, index) in mainNews.results"
                :key="index"
            >
                <a
                    class="main-event d-flex flex-column pb-4 text-white"
                    :href="news.has_redirect ? news.url : `/news/${news.id}`"
                    :target="news.has_redirect ? '_blank' : ''"
                >
                    <img
                        class="main-event__image position-absolute"
                        :src="news.image"
                        alt=""
                        style="object-position: center; object-fit: cover; width: 100%; height: 250px;"
                    >

                    <div class="main-event__overlay"></div>

                    <div class="main-event__info">
                        <div class="main-event__title mt-auto" style="font-weight: bold">
                            {{ news.title }}
                        </div>
                        <div class="main-event__description mt-3">
                            {{ news.short_description }}
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="d-flex w-100 justify-content-center mt-5" v-if="mainNews.count > DEFAULT_PAGE_SIZE">
            <nav>
                <ul class="pagination">
                    <li class="page-item" :class="pagination.previous.disabled ? 'disabled' : ''">
                        <a class="page-link" :href="pagination.previous.href">&laquo;</a>
                    </li>
                    <li
                        v-for="_page in pagination.pages"
                        :key='_page.href'
                        class="page-item"
                        :class="_page.active ? 'active' : ''"
                    >
                        <a class="page-link" :href="_page.href">{{ _page.page }}</a>
                    </li>
                    <li class="page-item" :class="pagination.next.disabled ? 'disabled' : ''">
                        <a class="page-link" :href="pagination.next.href">&raquo;</a>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router'

const DEFAULT_PAGE_SIZE = 12

export default {
    name: "News",

    setup() {
        const store = useStore()
        const route = useRoute()

        const { page } = route.query

        Promise.allSettled([
            store.dispatch('news/getPaginatedNews', page)
        ])

        const mainNews = computed(() => store.getters['news/paginatedNews'])

        const extractPageFromUrl = (_url) => {
            if (_url) {
                const url = new URL(_url)

                const urlSearchParams = new URLSearchParams(url.search)

                return urlSearchParams.get('page') || 1
            }
        }

        const pagination = computed(() => {
            const result = {
                previous: { disabled: !mainNews.value.previous, href: `/news?page=${extractPageFromUrl(mainNews.value.previous)}` },
                next: { disabled: !mainNews.value.next, href: `/news?page=${extractPageFromUrl(mainNews.value.next)}` },
                pages: []
            }

            const pagesCount = Math.ceil(mainNews.value.count / DEFAULT_PAGE_SIZE)

            for (let _page = 0; _page <= pagesCount; _page++) {
                result.pages.push({ active: Number(_page + 1) === Number(page), href: `/news?page=${_page + 1}`, page: _page + 1 })
            }

            return result
        })

        return {
            mainNews,
            pagination
        }
    }
    
}
</script>

<style>
.page-item .page-link {
    color: var(--primary-color-active);
}

.page-item.active .page-link {
    background-color: var(--primary-color-active);
    border-color: var(--primary-color-active);
}
</style>